/* the ~ allows you to reference things in node_modules */

// Overwrite Bootstrap variables
$enable-responsive-font-sizes: true;
$primary: #40A749;

// Bootstrap CSS
@import "~bootstrap/scss/bootstrap";


// Custom CSS

// General classes
html,
body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
}

.flex-even {
    flex: 1 1 0 !important;
}

.hide {
    display: none;
}

.nav-pills {
    font-size: 10px;
}

.mortisun-logo {
    max-height: 42px;
    // margin-top: -16px;
}

.bg-mortisun-dark {
    background-color: #11726B !important;
}

.bg-mortisun {
    background-color: #24867F !important;
}

.table-price-list td {
    vertical-align: top;
}

// 335px and up
@media (min-width: 335px) {
    .nav-pills {
        font-size: 11px;
    }
}
// 350px and up
@media (min-width: 350px) {
    .nav-pills {
        font-size: 12px;
    }
}
// 365px and up
@media (min-width: 365px) {
    .nav-pills {
        font-size: 13px;
    }
}
// 375px and up
@media (min-width: 375px) {
    .nav-pills {
        font-size: 14px;
    }
}
// Small devices and up
@include media-breakpoint-up(sm) {
    .nav-pills {
        font-size: unset;
    }
}

// End general classes


// Inspections

// -- Inspection hover
.inspection:hover {
    i {
        color: theme-color("primary");
    }
}

// End inspections


//  FAQ
    .faq-question-button {
        @extend .p-0;
        @extend .text-left;
    }
// End FAQ


// Callout
.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #eee;
    border-left-width: .25rem;
    border-radius: .25rem;

    h2,
    h3,
    h4,
    h5 {
        margin-top: 0;
        margin-bottom: .25rem
    }

    p:last-child {
        margin-bottom: 0
    }

    code {
        border-radius: .25rem
    }
}

.bd-callout-primary {
    border-left-color: $primary;

    h2,
    h3,
    h4,
    h5 {
        color: $primary
    }
}

.bd-callout-secondary {
    border-left-color: $secondary;

    h2,
    h3,
    h4,
    h5 {
        color: $secondary
    }
}

.bd-callout-success {
    border-left-color: $success;

    h2,
    h3,
    h4,
    h5 {
        color: $success
    }
}

.bd-callout-info {
    border-left-color: $info;

    h2,
    h3,
    h4,
    h5 {
        color: $info
    }
}

.bd-callout-warning {
    border-left-color: $warning;

    h2,
    h3,
    h4,
    h5 {
        color: $warning
    }
}

.bd-callout-danger {
    border-left-color: $danger;

    h2,
    h3,
    h4,
    h5 {
        color: $danger
    }
}

.bd-callout-light {
    border-left-color: $light;

    h2,
    h3,
    h4,
    h5 {
        color: $light
    }
}

.bd-callout-dark {
    border-left-color: $dark;

    h2,
    h3,
    h4,
    h5 {
        color: $dark
    }
}
// End callout